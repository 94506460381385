<template>
  <div class="page-layout detail-page details">

    <HeaderIntroImage backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container" v-if="!articleData">
      <div class="step">
        <br>
        <h3>Loading Data...</h3>
        <br>
      </div>
    </div>

    <div v-if="articleData" class="container">
      <div class="step">
        <p class="subtitle blue" v-html="newsDate"></p>

        <p v-if="articleData.field_ref_working_group"
          :class="['detail--tag', 'blue', 'icon-left', 'target'+articleData.field_ref_working_group.attributes.drupal_internal__nid]">
          {{ articleData.field_ref_working_group.attributes.title }}
        </p>


        <h2 class="title">{{ articleData.attributes.title }}</h2>

        <div class="content">

          <div class="deadline" v-if='articleData.attributes.field_news_type == "2"'>
            <p :class="{
              'deadline': true, 
              'past': new Date(articleData.attributes.field_opportunity_deadline) < Date.now()
            }">deadline</p>
            <p v-html="date(articleData.attributes.field_opportunity_deadline)"></p>
          </div>

          <p v-if="articleData.attributes.field_description" v-html="articleData.attributes.field_description.value">
          </p>

          <ImageItem :imagedata="articleData" :fieldname="'field_image'"
            :breakstyles="{'0': 'sm_image','576': 'md_image' , '1024': 'lg_image'}">
          </ImageItem>

          <div class="attachments-grid" v-if="articleData.field_attachment && articleData.field_attachment.length>0">
            <div class="box-row">
              <div class="box">
                <p class="label">Attachments</p>
                <a v-for="(att, index) in articleData.field_attachment" :key="'attachment'+index"
                  class="btn download-link slim bold plain"
                  target="_blank"
                  :href="endpoint + att.attributes.uri.url"
                  :download="att.attributes.filename">

                  <span class="download icon-right" v-if="articleData.relationships.field_attachment.data[index].meta.description != '' ">
                    {{articleData.relationships.field_attachment.data[index].meta.description}}
                  </span>

                  <span v-else class="download icon-right">{{ att.attributes.filename }}</span>
                </a>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
  import ImageItem from '../../components/atomic-elements/ImageItem.vue'
  import {
    fetchNodes
  } from '../../libs/drupalClient'

  export default {
    name: 'news',
    components: {
      HeaderIntroImage,
      ImageItem
    },
    props: {},
    data: () => {
      return {
        pageData: {
          attributes: {
            title: "",
            subtitle: "",
            tag: ""
          }
        },
        articleData: null
      }
    },
    computed: {
      endpoint(){
        return process.env.VUE_APP_ENDPOINT
      },
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      routesData() {
        return this.$store.state.routesData
      },
      objecttitle() {
        return this.$route.params.title
      },
      objectnid() {
        var link = this.routesData.filter(r => r.url_check == this.objecttitle)

        if (this.routesData.length <= 0) {
          return ''
        } else {
          if (link.length > 0)
            return link[0].nid
          else
            return '/not-found'
        }
      },
      newsDate() {
        var year = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { year: 'numeric' })
        var month = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { month: 'long' })
        var day = new Date(this.articleData.attributes.field_date).toLocaleDateString("en", { day: 'numeric' })

        return '<span>' + day + ' ' + month + ',</span> ' + year
      },
      newsimg() {
        if (this.articleData && this.articleData.field_image)
          return process.env.VUE_APP_ENDPOINT + this.articleData.field_image.attributes.uri.url
        else return ''
      },
    },
    methods: {
      date(date) {
        var year = new Date(date).toLocaleDateString("en", { year: 'numeric' })
        var month = new Date(date).toLocaleDateString("en", { month: 'short' })
        var day = new Date(date).toLocaleDateString("en", { day: 'numeric' })

        return '<span>' + day + ' ' + month+ '</span> ' + year
      },
      async findCT() {
        if (this.objectnid != '' && this.objectnid != '/not-found') {
          await fetchNodes('news', {
              include: ['field_ref_working_group', 'field_image', 'field_attachment', 'field_ref_project'],
              filters: [{
                key: 'drupal_internal__nid',
                value: this.objectnid
              }]
            })
            .then(res => {
              this.articleData = res[0]
             //console.log('this.articleData',this.articleData);
            })

        } else if (this.objectnid == '/not-found')
          this.$router.push(this.objectnid)
      }
    },
    async mounted() {
      await this.findCT()
    },
    watch: {
      async objectnid() {
        await this.findCT()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .deadline {
    display: flex;
    align-items: center;

    p {
      color: #071152;
      margin: 0;
      font-weight: 600;
      font-size: 14px;
    }

    .deadline {
      background-color: #ff0475;
      display: inline-block;
      padding: 7px 14px;
      border-radius: 5px;
      width: auto;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      margin: auto auto auto auto;
      margin-left: 0;
      margin-top: 0;
      margin-right: 10px;
    }

    &.past {
      background-color: #fff;
      color: #070A44;
      border: 1px solid #070A44;
    }

  }
  
  .btn.download-link {
    padding-left: 0;
    margin-left:0 ;
    border-left: 0em;
  }
</style>